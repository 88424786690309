<template>
    <div>
        <Header/>
        <div class="grace-body_2">
            <div class="grace-form">
                <div class="grace-form-item grace-border-b">
                    <div class="grace-form-label">
                        <span>郵箱</span>
                    </div>
                     <div class="grace-form-body">
                         <!-- replace(/\s+/g,'') 去除空額 -->
                        <input type="text"  :onkeyup="email = email.replace(/\s+/g,'')" v-model="email" class="grace-form-input_one" placeholder="請輸入郵箱">
                    </div>
                </div>
                <div class="grace-form-item grace-border-b">
                    <div class="grace-form-label">
                        <span>密碼</span>
                    </div>
                     <div class="grace-form-body">
                        <input type="password" :onkeyup="password = password.replace(/\s+/g,'')"  v-model="password" class="grace-form-input_one" placeholder="請輸入密碼">
                    </div>
                </div>
                <div class="grace-form-item grace-border-b">
                    <div class="grace-form-label">
                        <span>確認密碼</span>
                    </div>
                     <div class="grace-form-body">
                        <input type="password" :onkeyup="password_confirmation = password_confirmation.replace(/\s+/g,'')" v-model="password_confirmation" class="grace-form-input_one" placeholder="請輸入密碼">
                    </div>
                </div>

                <div class="grace-margin-top">
                    <button @click="registers">注冊</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Header from "./Header";
import { apiRegister } from '@/request/api';
export default {
  components:{Header},
  data(){
    return {
        email:'',
        password:'',
        password_confirmation:'',
    }
  },
  methods:{
    registers(){
        let email  = this.email;
        let password = this.password;
        let password_confirmation = this.password_confirmation;

        let regEmail = /^\w[-\w.+]*@([A-Za-z0-9][-A-Za-z0-9]+\.)+[A-Za-z]{2,14}/;
        if(!email){
            this.$message({
                message: '邮箱不能爲空',
                type: 'error'
            })
            return false;
        }else if(!regEmail.test(email)) {
            this.$message({
                message: '邮箱格式不正确',
                type: 'error'
            })
            return false;
        }

        if(!password){
             this.$message({
                message: '請填寫正確的密碼',
                type: 'error'
            })
            return false;
        }else if(password.length < 6){
             this.$message({
                message: '密碼位數不能小於6位',
                type: 'error'
            })
            return false;
        }


        if(password != password_confirmation){
            this.$message({
                message: '兩次輸入密碼不一致',
                type: 'error'
            })
            return false;
        }



        apiRegister({
            email:email,
            password:this.$md5(password),
            password_confirmation:this.$md5(password_confirmation),
        }).then(res=>{
            if(res.code == 200){
                this.$message({
                    message: '注冊成功',
                    type: 'success'
                })
                location.href="../";
            }else{
                 this.$message({
                    message: res.message,
                    type: 'error'
                })
            }
        }).catch(function (error) { // 请求失败处理
            this.$message.error(error.data);
            return '';
        });
    }
  }
}
</script>
<style>
::-webkit-input-placeholder{
    color:#888888;
  }
.grace-margin-top{
    margin-top: 15px;
}
.grace-margin-top button{
    width: 80%;
    background: #C49B6C !important;
    font-size: 15px;
    line-height: 44px;
    padding: 0;
    border-radius: 3px;
    border: none;
    color:#fff;
}
.grace-form-input_one{
        width: 100%;
    height: 20px;
    outline: none;
    line-height: 20px;
    margin: 10px 0;
    background: none;
    border: 0;
    text-align: right;
    color: #333;
    font-size: 13px;
    text-align: left;
}
.grace-form-body{
    width: 350px;
    margin-left: 10px;
    overflow: hidden;
}
.grace-form-label{
    text-align: left;
    width: 84px;
    display: block;
    width: 65px;
    height: 50px;
    font-size: 14px;
    line-height: 50px;
    -webkit-flex-shrink: 0;
    flex-shrink: 0;
    overflow: hidden;
    color: #888888;
}
.grace-border-b{
    border-bottom: 1px solid #838383;
}
.grace-form-item{
    padding: 5px 0;
    align-items: center;
    display: -webkit-flex;
}
.grace-form{
    margin-top: 5vh;
    display: block;
    width: 100%;
    height: auto;
    overflow: hidden;
}
.grace-body_2{
    padding: 20px;
    width: unset;
    height: 90vh;
    background: #fff;
    margin-top: -1rem;
}
  .skip{
    position: fixed;
    right: 30px;
    bottom: 20px;
    font-size: 14px;
  }
  
</style>